import https from '@/router/https'
//天气预报
export function getObj(param) {
    return https.fetchGet('/mallapp/juhe/weather', param);
}
//黄金数据接口
export function getGoldObj(param) {
    return https.fetchGet('/mallapp/juhe/gold', param);
}
//星座运势
export function getConstellationObj(param) {
    return https.fetchGet('/createrapp/dynamicState/xingzuo', param);
}