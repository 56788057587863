<template>
  <div>
    <div>

        <van-nav-bar
  title="黄金数据"
  left-arrow
  fixed="true"
   @click-left="$router.back(-1)"
/>
    </div>
    <!--数据-->
    <div class="goldDetail-bd4">
        <div>
          <span class="goldDetail-info6">{{this.golddata.variety}}（元/克）</span>
          <span class="goldDetail-word8">（更新时间{{this.golddata.time}}）</span>
          <span class="goldDetail-info7">{{this.golddata.latestpri}}</span>
        </div>
      </div>
<!--数据显示-->
  
<!---->
<div class="goldDetail-main">
  <div class="goldDetail-left"> 
     <div class="goldDetail-mod1 flex-col">
              <span class="goldDetail-info2">今日最低价(元/克)</span>
              <span class="goldDetail-word2">{{this.golddata.minpri}}</span>
            </div></div>     
<div class="goldDetail-mid"></div>     
<div class="goldDetail-right" ><div class="goldDetail-mod3 flex-col">
              <span class="goldDetail-info3">今日最高价(元/克)</span>
              <span class="goldDetail-word3">{{this.golddata.maxpri}}</span>
            </div></div> 
</div>  

<!---->
<div class="goldDetail-main1">
  <div>
          <span class="goldDetail-word4">其他价格信息</span>
          </div>
           <div class="goldDetail-layer2 flex-row">
            <van-col span="5"  offset="2">开盘价</van-col>
            <van-col span="10" offset="7">{{this.golddata.openpri}}(元/克)</van-col>
          </div>
           <div class="goldDetail-layer2 flex-row">
            <van-col  span="5"  offset="2">昨收价</van-col>
            <van-col span="10" offset="7">{{this.golddata.yespri}}(元/克)</van-col>
          </div>
           <div class="goldDetail-layer2 flex-row">
            <van-col  span="5"  offset="2">总成交量</van-col>
            <van-col span="10" offset="7">{{this.golddata.totalvol}}(元/克)</van-col>
          </div>
</div>  

  </div>
</template>
<script>
import {getGoldObj} from '@/api/weatherforecast/weatherforecast'
export default {
  data () {
    return {
      golddata:[],
    }
  },
   methods: {

   },
     created(){
    let params ={}
    getGoldObj(params).then((data) => {
    this.golddata=data.data.data.result[0][this.$route.query.index]
    //alert( this.golddata)
                }).catch(err=>{
                        console.log(err)   
                    }
                )
},
mounted()
{
  
}
}
</script>
<style lang="less" scoped>
.goldDetail-bd4 {
  height: 200px;
 background: url(/img/gold.png)
    100% repeat;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  position:fixed;
  left: 0;
  top: 50px;
}
.goldDetail-info6 {
  margin-top:30px;
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 26px;
  font-family: PingFangSC-Medium;
  line-height: 37px;
  text-align: center;
  align-self: center;
}
.goldDetail-word8 {
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: center;
  margin-top: 4px;
}

.goldDetail-info7 {
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 34px;
  font-family: MStiffHeiHKS-UltraBold;
  line-height: 47px;
  text-align: center;
  align-self: center;
  margin-top: 18px;
}



.goldDetail-outer4 {
  height: 87px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  align-self: center;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  padding-right: 26px;
  margin-top: 259px;
}


.goldDetail-info2 {

  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: center;
}

.goldDetail-word2 {

  display: block;
  overflow-wrap: break-word;
  color: rgba(16, 138, 0, 1);
  font-size: 23px;
  font-family: MStiffHeiHKS-UltraBold;
  line-height: 32px;
  text-align: center;
  align-self: center;
  margin-top: 4px;
}
.goldDetail-info3 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: center;
}

.goldDetail-word3 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(168, 0, 0, 1);
  font-size: 23px;
  font-family: MStiffHeiHKS-UltraBold;
  line-height: 32px;
  text-align: center;
  align-self: center;
  margin-top: 4px;
}

.goldDetail-main{
  margin-top: 259px;
  margin-left: 5%;
  margin-right: 5%;
  overflow:hidden;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  align-self: center;
  justify-content: center;
  width:90%;
  position:fixed;
  }
.goldDetail-left,.goldDetail-right{
   float:left; 
   width:40%; height:61px; }
.goldDetail-right{ float:right; }
.goldDetail-middle{ float:left;height:61px; background-color: rgba(216, 216, 216, 1); margin-left: 100px; }

.goldDetail-main1{
  margin-top: 3px;
  margin-left: 5%;
  margin-right: 5%;
  overflow:hidden;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  align-self: center;
  justify-content: center;
  height:150px;
  width:90%;
  position:fixed;
margin-top: 330px;
  }
  .goldDetail-word4 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: center;
  margin-top: 10px;
}
.goldDetail-info4 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: left;
}
.goldDetail-layer2 {
  margin-top: 10px;
  width: 100%;
  height: 22px;
  justify-content: space-between;

}
.goldDetail-txt1 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: right;
}
</style>
